import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ children }) => (
  <h3>{children}</h3>
)

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
Heading.defaultProps = {}

export default Heading
