import React, { useState } from 'react'
import PropTypes from 'prop-types'

import authStorage from '../../services/authStorage'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import logo from '../../images/logo_icon_red_153x154.png'
import './PersistedAccountsPicker.scss'

const PersistedAccountsPicker = ({ onSelectEmail }) => {
  const accounts = authStorage.getPersistedAccounts()
  const [, forceUpdate] = useState()

  if (!accounts.length) return null

  return (
    <div className="align-content-center d-flex flex-grow-1 flex-wrap justify-content-center p-2 persisted-accounts-container w-100">
      {accounts.length ? (
        accounts.map(account => (
          <div
            className="card cursor flex-column-center p-3 m-2 shadow"
            key={account.user.id}
            onClick={() => onSelectEmail(account.user)}
          >
            <div
              className="remove-icon"
              onClick={() => {
                authStorage.removePersistedAccount(account)
                forceUpdate(Math.random())
              }}
            >
              <FAIcon className="text-muted" name="times" />
            </div>

            {account.organization.logoUrl && (
              <img
                alt={account.organization.name}
                src={account.organization.logoUrl}
                style={{ maxHeight: '75px', maxWidth: '75px' }}
              />
            )}

            <div className="mt-3">
              {account.user.firstName} {account.user.lastName}
            </div>

            <div className="text-muted">{account.user.title}</div>

            <div className="text-muted">{account.organization.name}</div>
          </div>
        ))
      ) : (
        <div className="align-items-center d-flex w-100">
          <img
            alt="THERMS Security Reporting & Management Software"
            className="logo m-auto"
            src={logo}
          />
        </div>
      )}
    </div>
  )
}

PersistedAccountsPicker.propTypes = {
  onSelectEmail: PropTypes.func.isRequired,
}
PersistedAccountsPicker.defaultProps = {}

export default PersistedAccountsPicker
