import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Loading from '@therms/web-common/components/Loading'
import Context from './Context'
import LoginContainer from './containers/Login/LoginContainer'
import authStorage from './services/authStorage'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './Container.scss'

import logoImage from 'images/legacy-light-vertical.png'

const Container = props => {
  const [pathState, setPathState] = useState({
    path: props.path || '/Login',
    state: {},
  })
  const [showTopLevelNav, setShowTopLevelNav] = useState(props.showTopLevelNav)
  const [showCancel, setShowCancel] = useState()

    function setPath(path = '/Login', state = {}) {
    setPathState({ path, state })
  }

  const { path } = pathState
  let PathContainer = Loading

  if (/^\/ForgotPassword/.test(path))
    PathContainer = React.lazy(() => import('./containers/ForgotPassword/Flow'))
  if (/^\/Login/.test(path)) PathContainer = LoginContainer
  if (/^\/Register/.test(path))
    PathContainer = React.lazy(() => import('./containers/Register/Flow'))
  if (/^\/Verify/.test(path))
    PathContainer = React.lazy(() =>
      import('./containers/Verify/VerifyContainer'),
    )

  const getTopLevelBtnClass = btnPath =>
    classnames('btn-nav-path', { active: path.match(btnPath) })

  useEffect(() => {
    authStorage.resetAuth()
  }, [])

  return (
    <Context.Provider
      value={{
        path,
        setPath,
        setShowCancel,
        setShowTopLevelNav,
        showCancel,
        showTopLevelNav,
        state: pathState.state,
      }}
    >
      <div id="app" className="d-flex flex-column p-5">
        <img src={logoImage} alt="" className="logo-image mx-auto mt-3" />

        {showTopLevelNav && (
          <div className="d-flex w-100">
            <button
              className={getTopLevelBtnClass('Login')}
              onClick={() => setPath('/Login')}
              type="button"
            >
              Login
            </button>

            <button
              className={getTopLevelBtnClass('Register')}
              onClick={() => setPath('/Register')}
              type="button"
            >
              Register
            </button>
          </div>
        )}

        {!showTopLevelNav && showCancel && (
          <div className="toplevel-cancel-container">
            <button
              className="btn"
              onClick={() => {
                setShowCancel(false)
                setShowTopLevelNav(props.showTopLevelNav)
                setPath('/Login')
              }}
            >
              <FAIcon lg name="times" />
            </button>
          </div>
        )}

        <div className="flex-column-center flex-grow-1 w-100">
          <React.Suspense
            fallback={(
              <div className="my-3">
                <Loading text="Loading..." />
              </div>
            )}
          >
            <PathContainer />
          </React.Suspense>
        </div>

        {/* <a */}
        {/*  href="https://www.therms.io/tag/updates" */}
        {/*  className="text-center my-2" */}
        {/*  rel="noopener noreferrer" */}
        {/*  target="_blank" */}
        {/* > */}
        {/*  <strong>What's new?</strong> */}
        {/* </a> */}
      </div>
    </Context.Provider>
  )
}

Container.propTypes = {
  path: PropTypes.string, // optional initial path
  showTopLevelNav: PropTypes.bool, // optional hide btns to nav btween login/register
}
Container.defaultProps = {
  showTopLevelNav: true,
}

export default Container
