// util services for storing auth info on client
import cookie from 'js-cookie'

const CORE_COOKIE_NAME = process.env.CORE_AUTH_COOKIE_NAME
const PORTAL_COOKIE_NAME = process.env.PORTAL_AUTH_COOKIE_NAME
const EXPIRE = process.env.COOKIE_EXPIRE
const LSKEY_ACCOUNTS_PERSIST = 'USERS_PERSIST'

if (process.env.NODE_ENV !== 'production') {
  window.cookie = cookie
}

export default {
  getAuth() {
    let userCookie

    try {
      // check for cookie
      userCookie = cookie.getJSON(CORE_COOKIE_NAME)
      // check for localStorage
    } catch (e) {
      console.log('localStorage auth token not JSON')
    }

    return userCookie || null
  },

  getPersistedAccounts() {
    try {
      return JSON.parse(localStorage.getItem(LSKEY_ACCOUNTS_PERSIST) || '[]')
    } catch (e) {
      return []
    }
  },

  persistUserOrg({ organization, user }) {
    let accounts = []

    try {
      accounts = JSON.parse(
        localStorage.getItem(LSKEY_ACCOUNTS_PERSIST) || '[]',
      )
    } catch (e) {
      console.warn('error parsing LSKEY_ACCOUNTS_PERSIST', e)
    }

    if (!accounts.find(account => account.user.id === user.id))
      accounts.push({ organization, user })

    localStorage.setItem(LSKEY_ACCOUNTS_PERSIST, JSON.stringify(accounts))
  },

  removePersistedAccount(account) {
    let accounts = []

    try {
      accounts = JSON.parse(
        localStorage.getItem(LSKEY_ACCOUNTS_PERSIST) || '[]',
      )
    } catch (e) {
      console.warn('error parsing LSKEY_ACCOUNTS_PERSIST', e)
    }

    accounts = accounts.filter(_account => _account.user.id !== account.user.id)

    localStorage.setItem(LSKEY_ACCOUNTS_PERSIST, JSON.stringify(accounts))
  },

  resetAuth() {
    try {
      cookie.remove(PORTAL_COOKIE_NAME, {
        domain: process.env.COOKIE_DOMAIN,
      })

      cookie.remove(CORE_COOKIE_NAME, {
        domain: process.env.COOKIE_DOMAIN,
      })
    } catch (e) {
      console.warn('resetAuth()', e)
    }
  },

  setAuth(authToken, userType) {
    try {
      const cookieName =
        userType === 'portalUser' ? PORTAL_COOKIE_NAME : CORE_COOKIE_NAME

      // save cookie
      cookie.set(cookieName, authToken, {
        domain: process.env.COOKIE_DOMAIN,
        expires: parseInt(EXPIRE, 10) || 30,
      })
    } catch (e) {
      console.warn('setAuthCookie()', e)
    }
  },
}
