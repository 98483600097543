import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

import Container from 'Container'
import ErrorBoundary from 'containers/ErrorBoundary/index'

import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@therms/web-common/styles/index.scss'
import './index.css'

ReactDOM.render(
  <ErrorBoundary>
    <Container showTopLevelNav={false} />
  </ErrorBoundary>,
  document.querySelector(`#web-auth-container`),
)
