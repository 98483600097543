import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Heading from 'components/Heading'

import api from 'services/api'

class PickUser extends Component {
  static propTypes = {
    loginToken: PropTypes.string.isRequired,
    onLoginFail: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    userAccountId: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
  }

  pickUser = selectedUserId => {
    const { id: userId } = this.props.users.find(
      user => user.id === selectedUserId,
    )

    api
      .loginWithLoginToken({
        loginToken: this.props.loginToken,
        userAccountId: this.props.userAccountId,
        userId,
      })
      .then(({ data }) => {
        this.props.onLoginSuccess(data)
      })
      .catch(e => {
        console.log('failed to login', e)
        this.props.onLoginFail()
      })
  }

  renderUserList = () =>
    this.props.users.map(user => (
      <div
        className={classnames('card p-3 my-2', {
          'bg-light text-dark': user.type === 'portalUser',
        })}
        key={user.id}
        onClick={() => this.pickUser(user.id)}
        style={{ cursor: 'pointer' }}
      >
        <div className="d-flex justify-content-around">
          <div className="align-items-center d-flex flex-column justify-content-center mr-3">
            {user.organization && user.organization.logoUrl && (
              <img
                className="mb-2"
                alt={user.organization.name}
                src={user.organization.logoUrl}
                style={{ maxWidth: '75px' }}
              />
            )}

            <h5 className="text-center">
              {user.type === 'user' ? user.organization.name : 'Portal Account'}
            </h5>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div>
              <strong>
                {user.lastName},{user.firstName}
              </strong>
            </div>

            {user.title && <em>{user.title}</em>}
          </div>
        </div>
      </div>
    ))

  render() {
    return (
      <div id="PickUser" className="w-100 mt-5 px-3">
        <Heading>Pick User Account</Heading>

        <div className="d-flex flex-column mb-5">{this.renderUserList()}</div>
      </div>
    )
  }
}

export default PickUser
