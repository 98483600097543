// auth api services
import axios from 'axios'

export const authServer = axios.create({
  baseURL: process.env.AUTH_SERVER,
})

const api = {
  createOrganization(values) {
    return authServer.post('/register', values)
  },

  login(values) {
    return authServer.post(
      '/login',
      values,
      { withCredentials: true },
    )
  },

  loginWithLoginToken(values) {
    return authServer.post(
      '/login/oneTime',
      values,
      { withCredentials: true },
    )
  },

  // todo: refactor this process
  resendVerificationEmail(userId) {
    return authServer.post('/user/resendVerficiationEmail', { userId })
  },
}

export default api
