import React, { useContext, useState } from 'react'
import qs from 'query-string'
// import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import useKeypress from 'react-use-keypress'
import { get } from 'lodash-es'
import { FORM_ERROR } from 'final-form'

import Loading from '@therms/web-common/components/Loading'
import authStorage from '../../services/authStorage'
import authApi from '../../services/authApi'
import Context from '../../Context'
import LoginForm from '../../components/Login/LoginForm'
import PickUser from '../../components/Login/PickUser'
import encryption from '../../services/encryption'
import { simpleEncrypt } from '../../utils/simple-encrypt'

const LoginContainer = () => {
  const [urlParams] = useState(qs.parse(window.location.search))
  const [loginSuccess, setLoginSuccess] = useState()
  const [pickUser, setPickUser] = useState()
  const context = useContext(Context)

  const handleLoginSuccess = data => {
    setLoginSuccess(true)
    // set cookie
    authStorage.setAuth(data.authToken, data.user.type)

    if (data.user.type === 'portalUser')
      return (window.location.href = process.env.PORTAL_URL)

    // persist user/org
    authStorage.persistUserOrg({
      organization: data.user.organization,
      user: data.user,
    })

    if (urlParams.redirect) {
      return window.location.href = urlParams.redirect
    }

    // go to APP dashboard
    return (window.location.href = process.env.DASHBOARD_URL)
  }

  if (loginSuccess)
    return (
      <div className="my-5">
        <Loading text={<h4>Login Successful</h4>} />
      </div>
    )

  if (pickUser)
    return (
      <div className="my-5">
        <PickUser
          loginToken={pickUser.loginToken}
          onLoginFail={() => setPickUser()}
          onLoginSuccess={handleLoginSuccess}
          userAccountId={pickUser.userAccountId}
          users={pickUser.users}
        />
      </div>
    )

  return (
    <Form
      initialValues={{
        email: get(context, 'state.email'),
        password: get(context, 'state.password'),
      }}
      onSubmit={async values => {
        try {
          const { data } = await authApi.post('/login', {
            email: values.email,
            password: values.password,
            userId: values.userId,
          })

          if (process.env.NODE_ENV !== 'production')
            console.log('login data', JSON.stringify(data))

          // ws-auth will return this data if Infinity login
          if (data.redirectToInfinity && data.infinityAuthToken) {
            setLoginSuccess(true)
            return (window.location.href = `https://therms.app?authToken=${encodeURIComponent(
              // Infinity @therms/react-modules package uses this algorithm for url authToken param
              simpleEncrypt(data.infinityAuthToken),
            )}`)
          }

          // handle a v2 user login
          if (data.v2Login)
            return (window.location.href = `https://v2.therms.io/login-redirect?email=${encodeURIComponent(
              values.email,
            )}&password=${encodeURIComponent(
              encryption.encrypt(values.password),
            )}`)

          // turn off email verifications - the User's password is sent to their email when created
          // if (data.emailVerificationNeeded)
          //   return context.setPath('/Verify', { email: values.email })

          if (data.users) return setPickUser(data)

          return handleLoginSuccess(data)
        } catch (error) {
          const errMsg = get(
            error,
            'response.data.message',
            'Unable to authenticate, try again',
          )

          return { [FORM_ERROR]: errMsg }
        }
      }}
      render={({ handleSubmit, submitting, form, submitError }) => {
        if (urlParams.email && urlParams.encryptedPassword) {
          if (urlParams.userId) {
            form.change('userId', urlParams.userId)
          }

          form.change('email', urlParams.email)
          form.change(
            'password',
            encryption.decrypt(urlParams.encryptedPassword),
          )

          // remove the url params from browser history
          const searchParams = qs.parse(window.location.search)

          delete searchParams.userId
          delete searchParams.email
          delete searchParams.encryptedPassword

          window.history.replaceState(
            {},
            null,
            `${window.location.pathname}?${qs.stringify(searchParams)}`,
          )

          handleSubmit()
        } else if (urlParams.email && urlParams.password) {
          if (urlParams.userId) {
            form.change('userId', urlParams.userId)
          }

          form.change('email', urlParams.email)
          form.change('password', urlParams.password)

          // remove the url params from browser history
          window.history.replaceState({}, null, window.location.pathname)

          handleSubmit()
        } else if (urlParams.email) {
          form.change('email', urlParams.email)

          window.history.replaceState({}, null, window.location.pathname)
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useKeypress('Enter', () => {
          handleSubmit()
        })

        if (submitting)
          return (
            <div className="my-5">
              <Loading text={<h4>Authenticating</h4>} />
            </div>
          )

        return (
          <div className="flex-column-center flex-grow-1 w-100">
            <LoginForm />

            {submitError && (
              <div className="text-center">
                <p className="alert text-danger text-uppercase">
                  {submitError}
                </p>

                <p className="text-center text-primary">
                  <a href="https://therms.app" rel="noopener noreferrer">
                    <strong>Try Infinity Login?</strong>
                  </a>
                </p>
              </div>
            )}

            <button
              className="btn btn-red-brand max-width-300"
              onClick={handleSubmit}
              type="submit"
            >
              Login
            </button>

            <p className="mt-3 text-secondary">
              By logging in, I agree to the{' '}
              <a href="https://www.therms.io/terms/">terms of service</a>
            </p>

            <div className="mt-3">
              <button
                className="btn btn-link"
                onClick={() => context.setPath('/ForgotPassword')}
                type="button"
              >
                Reset Password
              </button>
            </div>
          </div>
        )
      }}
    />
  )
}

LoginContainer.propTypes = {}
LoginContainer.defaultProps = {}

export default LoginContainer
