import React from 'react'
// import PropTypes from 'prop-types'
import { Field, useForm } from 'react-final-form'
import { formValidate } from '@therms/util-services'

import PersistedAccountsPicker from './PersistedAccountsPicker'
import Text from '@therms/web-common/components/Inputs/Text'

const OrganizationInfoForm = () => {
  const form = useForm()

  return (
    <div className="flex-column-center w-100 p-3">
      <div className="mb-2">
        <PersistedAccountsPicker
          onSelectEmail={user => {
            const email = user.userAccount.email

            if (email !== form.getFieldState('email').value) {
              form.reset()
              form.change('email', email)
              form.change('password', '')
              form.resetFieldState('password')
            }

            form.change('userId', user.id)
          }}
        />
      </div>

      <div className="max-width-300 w-100">
        <Field
          component={Text}
          name="email"
          placeholder="Email"
          validate={formValidate.composeValidate(
            formValidate.required,
            // v2 logins use usernames
            // formValidate.email,
          )}
        />

        <Field
          component={Text}
          name="password"
          placeholder="Password"
          type="password"
          validate={formValidate.required}
        />
      </div>
    </div>
  )
}

OrganizationInfoForm.propTypes = {}
OrganizationInfoForm.defaultProps = {}

export default OrganizationInfoForm
