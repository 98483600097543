import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import pkg from '../../../package'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV)
      Sentry.init({
        dsn: 'https://31e94c57043843eaa7308c28b7055346@sentry.io/284712',
        environment: process.env.DEPLOY_ENV,
        // process.env.RELEASE set in webpack bundling
        release: process.env.RELEASE || `v${pkg.version}-NO_RELEASE_ENV-${new Date().getTime()}`,
      })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.captureException(error, { extra: errorInfo })
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className="w-100 h-100 mx-auto text-center">
          <h3 className="text-danger">We're sorry — something's gone wrong.</h3>

          <p className="text-muted">
            Our team has been notified. You can refresh and try again.
          </p>

          <p>
            <button
              className="btn btn-primary"
              onClick={() => window.location.reload()}
              type="button"
            >
              Reload and Try Again
            </button>
          </p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
