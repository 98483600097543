export default {
  decrypt(content, passcode = 'therms') {
    const result = []
    let str = ''
    const codesArr = JSON.parse(content)
    const passLen = passcode.length

    for (let i = 0; i < codesArr.length; i++) {
      const passOffset = i % passLen
      const calAscii = codesArr[i] - passcode.charCodeAt(passOffset)
      result.push(calAscii)
    }

    for (let i = 0; i < result.length; i++) {
      const ch = String.fromCharCode(result[i])
      str += ch
    }

    return str
  },

  encrypt(content, passcode = 'therms') {
    const result = []
    const passLen = passcode.length

    for (let i = 0; i < content.length; i++) {
      const passOffset = i % passLen
      const calAscii = content.charCodeAt(i) + passcode.charCodeAt(passOffset)
      result.push(calAscii)
    }

    return JSON.stringify(result)
  },
}
